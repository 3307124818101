import '../styles/Resume.scss';
import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-intersection-observer';
import { Button, Icon } from 'semantic-ui-react';



export default function Resume() {
    const { ref: myRef, inView: myElementIsVisible } = useInView();
    const isMobile = useMediaQuery({ query: `(max-width: 480px)` });

    return (
        <div id='resume' className={`resume-container ${myElementIsVisible ? 'in-view-animate' : ''}`} ref={myRef}>
            
            
            <div className='experience'>
                <div className='exp-header'>
                    <h3>Experience</h3>
                    <a href='https://resume-docs.s3.amazonaws.com/vic_bottali_resume.pdf' target="_blank" className='download-button'>
                        <Button animated='vertical' color='violet' inverted>
                            <Button.Content visible icon>
                                <Icon fitted size='large' name='file pdf'></Icon>
                            </Button.Content>
                            <Button.Content hidden icon>
                                <Icon size='large' name='cloud download'></Icon>
                            </Button.Content>
                        </Button>
                    </a>
                </div>
                <div className='exp-container one'>
                    <div className='circle'></div>
                    <div className='title'>
                        <p className='company'>KOgent Systems LLC</p>
                        <p className='location'>New Paltz, NY</p>
                        <p className='role'>Software Consultant</p>
                    </div>
                    <div className='dates'>
                        <p>{isMobile ? 'Dec 2020 - Aug 2022': 'December 2020 - August 2022'}</p>
                        <p>1 year, 9 months</p>
                    </div>

                    <div className='details'>
                        <p className='desc'>
                            Provided maintenance for existing systems, in addition to building out new features for various clients as part of a team of consultants.
                        </p>
                        <ul>
                            <li>Built a B2B system to manage returned inventory for an auto parts distributor–including a new web portal using Angular, and extending their existing Java API’s and IBM DB2 database </li>
                            <li>Maintained and fixed bugs  in multiple systems across different industries, most commonly in Angular and Java environments</li>
                        </ul>
                    </div>
                </div>
                <div className='exp-container two'>
                    <div className='circle'></div>
                    <div className='title'>
                        <p className='company'>SUNY New Paltz</p>
                        <p className='location'>New Paltz, NY</p>
                        <p className='role'>Adjunct Lecturer</p>
                    </div>
                    <div className='dates'>
                        <p>{isMobile ? 'Jan 2020 - Jun 2020' : 'January 2020 - June 2020'}</p>
                        <p>5 months</p>
                    </div>

                    <div className='details'>
                        <p className='desc'>
                            Taught the Web Design course at the State University of New York at New Paltz.
                        </p>
                        <ul>
                            <li>Developed the syllabus and learning materials and resources  covering the basics of building web pages </li>
                            <li>Adapted to remote teaching by creating videos and more interactive content for students</li>
                        </ul>
                    </div>
                </div>
                <div className='exp-container three'>
                    <div className='circle'></div>
                    <div className='title'>
                        <p className='company'>SCA</p>
                        <p className='location'>Red Hook, NY</p>
                        <p className='role'>Lead Software Engineer</p>
                    </div>
                    <div className='dates'>
                        <p>{isMobile ? 'May 2016 - Dec 2019' : 'May 2016 - December 2019'}</p>
                        <p>3 years, 8 months</p>
                    </div>

                    <div className='details'>
                        <p className='desc'>
                            Worked at a SaaS company creating software solutions for municipalities. Started as an intern (May 2016- July 2016), was promoted to software engineer (July 2016 - August 2018), and then became a lead (August 2018 - December 2019).
                        </p>
                        <ul>
                            <li>Built new features into the platform to meet the specific needs of different  municipalities - involving designing database structures (MSSQL), building UI components (ExtJs/Angular), and extending API capabilities (.NET/C#)</li>
                            <li>Examined and remedied performance bottlenecks in the backend as the company scaled</li>
                            <li>Managed Jenkins CI/CD environment</li>
                            <li>Translated customer requirements into actionable items for the development team</li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div className='skills'>
                <h3>Skills</h3>
                <div className='languages'>
                    <div className='title'>Languages</div>
                    <div className='chips'>
                        <div className='chip'>Typescript</div>
                        <div className='chip'>Javascript</div>
                        <div className='chip'>HTML/CSS</div>
                        <div className='chip'>C#</div>
                        <div className='chip'>Python</div>
                        <div className='chip'>SQL</div>
                        <div className='chip'>Java</div>
                    </div>
                </div>
                
                <div className='frameworks'>
                    <div className='title'>Frameworks</div>
                    <div className='chips'>
                        <div className='chip'>React</div>
                        <div className='chip'>Angular</div>
                        <div className='chip'>ExtJs</div>
                        <div className='chip'>.NET Framework</div>
                        <div className='chip'>Express</div>
                        <div className='chip'>Flask</div>
                        <div className='chip'>Spring Boot</div>
                    </div>
                </div>
                
                <div className='other-tools'>
                    <div className='title'>Other Tools</div>
                    <div className='chips'>
                        <div className='chip'>AWS</div>
                        <div className='chip'>Git</div>
                        <div className='chip'>Jenkins</div>
                        <div className='chip'>Postman</div>
                        <div className='chip'>Node</div>
                        <div className='chip'>Sass</div>
                        <div className='chip'>SSMS</div>
                        <div className='chip'>DBeaver</div>
                        <div className='chip'>Leaflet</div>
                        <div className='chip'>Serverless Framework</div>
                    </div>
                </div>

                <div className='education'>
                    <h3>Education</h3>
                    <p>State University of New York at New Paltz (SUNY)</p>
                    <p className='degree'>B.S in Computer Science</p>
                    <p className='dates'>August 2012 - May 2016</p>
                </div>
            </div>

       </div>
    )
}