import gitLogo from './githubLogo.png';
import React, { useRef, useState } from 'react';
import Resume from './components/Resume';
import './App.scss';
import './styles/AppMobile.scss';
import 'semantic-ui-css/semantic.min.css';
import { Button, Icon, Sidebar, Menu } from 'semantic-ui-react';



function App() {
    const segmentRef = React.useRef();
    const [sidebarVisible, setSidebarVisible] = React.useState(false);

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    }

    return (
        <div>
            <div className='header-container'>
                <header className="header-bar">

                    <div id="menu-btn" onClick={toggleSidebar} className={sidebarVisible ? "open" : ""}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                    <div className="nav-bar">
                        <a id='home-btn' href="#home">
                            <div className="btn-border left"></div>
                            <span>About</span>
                            <div className='btn-border right'></div>
                        </a>
                        <a id='resume-btn' href="#resume">
                            <div className="btn-border left"></div>
                            <span>Resume</span>
                            <div className='btn-border right'></div>
                        </a>
                        <a id='projects-btn' href="#projects">
                            <div className="btn-border left"></div>
                            <span>Projects</span>
                            <div className='btn-border right'></div>
                        </a>
                    </div>
                </header>

                <div className="gradient-bar">
                </div>
            </div>

            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    icon='labeled'
                    inverted
                    onHide={() => setSidebarVisible(false)}
                    vertical
                    direction='top'
                    visible={sidebarVisible}
                    className='sidebar-menu'
                    width='thin'
                >
                    <Menu.Item as='a' href="#home" onClick={() => setSidebarVisible(false)}>About</Menu.Item>
                    <Menu.Item as='a' href="#resume" onClick={() => setSidebarVisible(false)} >Resume</Menu.Item>
                    <Menu.Item as='a' href="#projects" onClick={() => setSidebarVisible(false)} >Projects</Menu.Item>
                    <Menu.Item as='a' href='https://resume-docs.s3.amazonaws.com/vic_bottali_resume.pdf' target="_blank" onClick={() => setSidebarVisible(false)} >Resume PDF</Menu.Item>
                    <div className='menu-bottom'></div>
                </Sidebar>

                <Sidebar.Pusher dimmed={sidebarVisible}>
                    <div className="portfolio" >

                        <div id="home" className="home scroll-snap">
                            <div className='name-container'>
                                <h1>Hi,</h1>
                                <h1>I'm Vic Bottali</h1>
                                <p>I'm an experienced full stack software engineer that
                                    enjoys building things for the internet,
                                    architecting scalable systems,
                                    and continuously learning.
                                </p>
                            </div>

                            <div className='arrow-down'>
                                <Icon name='chevron down' size='large'></Icon>
                                <Icon name='chevron down' size='large'></Icon>
                                <Icon name='chevron down' size='large'></Icon>
                            </div>
                        </div>

                        <Resume id="resume" className='scroll-snap'></Resume>

                        <div id="projects" className="projects scroll-snap">
                            <h2>Projects</h2>
                            <div className='project-container'>
                                <div className='project-card'>
                                    <div className='project-header'>
                                        <div className='project-name'>Brunch DAO</div>
                                        <a href="https://github.com/vicbottali/brunch"><img src={gitLogo} alt="git logo"></img></a>
                                    </div>
                                    <div className='details'>
                                        Project submission for an EthGlobal Hackathon, exploring the Solidity programming language and tooling around blockchain development.
                                    </div>
                                </div>

                                <div className='project-card'>
                                    <div className='project-header'>
                                        <div className='project-name'>LED Light Controller</div>
                                        <a href="https://github.com/vicbottali/light-control"><img src={gitLogo} alt="git logo"></img></a>
                                    </div>
                                    <div className='details'>
                                        Web application using Node and React to control RGB light bulbs and LED strips.
                                    </div>
                                </div>

                                <div className='project-card'>
                                    <div className='project-header'>
                                        <div className='project-name'>Hydroponic Monitoring System</div>
                                        <a href="https://github.com/vicbottali/hydro-service"><img src={gitLogo} alt="git logo"></img></a>
                                    </div>
                                    <div className='details'>
                                        Flask application on a Raspberry Pi monitoring temperature, PH, and humidity for a hydroponic system, and uploading the data to  DynamoDB for retrieval via an API endpoint.
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </div >
    );
}

export default App;
